import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'app-popup-state',
    templateUrl: './popup-state.component.html',
    styleUrls: ['./popup-state.component.scss']
})
export class PopupStateComponent implements OnInit {
    @Input()
    show:boolean = false;
    @Input()
    success = false;
    @Input()
    goNext = false;
    @Input()
    agreement:boolean = false;
    @Input()
    showContinue:boolean = false;
    @Input()
    message = 'Something went wrong. Please try again.';
    @Input()
    data:any = {};

    @Output() close = new EventEmitter();
    @Output() confirm = new EventEmitter();
    @Output() next = new EventEmitter();

    constructor() { }

    ngOnInit() {

    }


    closePopup() {
        this.show = false;
        this.close.emit();
    }

    onConfirm() {
        this.closePopup();
        this.confirm.emit(this.data);
    }
    gotoNext(){
        this.next.emit();
    }
}
